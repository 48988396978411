import db from '@/db'
import firebase from '@/firebase'

import { 
  PUSH_INTENT_MEAN_CONTEXT_CATEGORY,
  PUSH_INTENT_MEAN_PARENT_CATEGORY,
  PUSH_INTENT_MEAN_TAG,
  PUSH_END_CONTEXT_CATEGORY,
  PUSH_END_TAG,
  PUSH_VALUE_CONTEXT_CATEGORY,
  PUSH_VALUE_TAG,
  PUSH_ACTION_MEAN_CONTEXT_CATEGORY,
  PUSH_ACTION_MEAN_TAG,

  SET_HOLDER_CB,

  SET_IMVHOLDER,
  SET_IMVMHOLDER,
  SET_IMNHOLDER,
  SET_IMNMHOLDER,
  SET_IMCHOLDER,

  SET_EVHOLDER,
  SET_EVMHOLDER,
  SET_ENHOLDER,
  SET_ENMHOLDER,
  SET_ECHOLDER,

  SET_VVHOLDER,
  SET_VVMHOLDER,
  SET_VNHOLDER,
  SET_VNMHOLDER,
  SET_VCHOLDER,

  SET_AMVHOLDER,
  SET_AMVMHOLDER,
  SET_AMNHOLDER,
  SET_AMNMHOLDER,
  SET_AMCHOLDER,

  SET_INTENT_MEAN_VALUES,
  SET_END_VALUES,
  SET_VALUE_VALUES,
  SET_ACTION_MEAN_VALUES,

  SET_INTENT_MEAN_TAGS,
  SET_END_TAGS,
  SET_VALUE_TAGS,
  SET_ACTION_MEAN_TAGS,

  SET_TRAINING_EXAMPLE_COUNT,

  RESET_VALUES_AND_TAGS

} from './mutations'

  const state = {
    intentMeanContextCategoryList: [],
    endContextCategoryList: [],
    valueContextCategoryList: [],
    actionMeanContextCategoryList: [],

    intentMeanParentCategoryList: [],

    intentMeanTagList: [],
    endTagList: [],
    valueTagList: [],
    actionMeanTagList: [],

    imvHolder: [],
    imvmHolder: [],
    imnHolder: [], 
    imnmHolder: [],
    imcHolder: [],
    evHolder: [],
    evmHolder: [],
    enHolder: [], 
    enmHolder: [],
    ecHolder: [],
    vvHolder: [],
    vvmHolder: [],
    vnHolder: [], 
    vnmHolder: [],
    vcHolder: [],
    amvHolder: [],
    amvmHolder: [],
    amnHolder: [], 
    amnmHolder: [],
    amcHolder: [],

    intentMeanContextCategoryListSelect: null,
    endContextCategoryListSelect: null,
    valueContextCategoryListSelect: null,
    actionMeanContextCategoryListSelect: null,
    intentMeanParentCategoryListSelect: null,

    intentMeanTagListSelect: null,
    endTagListSelect: null,
    valueTagListSelect: null,
    actionMeanTagListSelect: null,

    intentMeanSpecificityLevelListSelect: null,
    actionMeanSpecificityLevelListSelect: null,

    trainingExampleCount: 0,



  }
  
  const getters = {
    intentMeanContextCategoryList: ({intentMeanContextCategoryList}) => intentMeanContextCategoryList,
    endContextCategoryList: ({endContextCategoryList}) => endContextCategoryList,
    valueContextCategoryList: ({valueContextCategoryList}) => valueContextCategoryList,
    actionMeanContextCategoryList: ({actionMeanContextCategoryList}) => actionMeanContextCategoryList,
    
    intentMeanTagList: ({intentMeanTagList}) => intentMeanTagList,
    intentMeanTagListCount: (getters) => {
      return getters.intentMeanTagList.length
    },
    endTagList: ({endTagList}) => endTagList,
    valueTagList: ({valueTagList}) => valueTagList,
    actionMeanTagList: ({actionMeanTagList}) => actionMeanTagList,


    intentMeanContextCategoryListSelect: ({intentMeanContextCategoryListSelect}) => intentMeanContextCategoryListSelect,
    endContextCategoryListSelect: ({endContextCategoryListSelect}) => endContextCategoryListSelect,
    valueContextCategoryListSelect: ({valueContextCategoryListSelect}) => valueContextCategoryListSelect,
    actionMeanContextCategoryListSelect: ({actionMeanContextCategoryListSelect}) => actionMeanContextCategoryListSelect,

    intentMeanParentCategoryListSelect: ({intentMeanParentCategoryListSelect}) => intentMeanParentCategoryListSelect,

    intentMeanTagListSelect: ({intentMeanTagListSelect}) => intentMeanTagListSelect,
    endTagListSelect: ({endTagListSelect}) => endTagListSelect,
    valueTagListSelect: ({valueTagListSelect}) => valueTagListSelect,
    actionMeanTagListSelect: ({actionMeanTagListSelect}) => actionMeanTagListSelect,

    intentMeanSpecificityLevelListSelect: ({intentMeanSpecificityLevelListSelect}) => intentMeanSpecificityLevelListSelect,
    actionMeanSpecificityLevelListSelect: ({actionMeanSpecificityLevelListSelect}) => actionMeanSpecificityLevelListSelect,

    trainingExampleCount: ({trainingExampleCount}) => trainingExampleCount,

    imvHolder: state => {
      return state.imvHolder
    },
    imvmHolder: ({imvmHolder}) => imvmHolder,
    imnHolder: ({imnHolder}) => imnHolder,
    imnmHolder: ({imnmHolder}) => imnmHolder,
    imcHolder: ({imcHolder}) => imcHolder,

    evHolder: ({evHolder}) => evHolder,
    evmHolder: ({evmHolder}) => evmHolder,
    enHolder: ({enHolder}) => enHolder,
    enmHolder: ({enmHolder}) => enmHolder,
    ecHolder: ({ecHolder}) => ecHolder,

    vvHolder: ({vvHolder}) => vvHolder,
    vvmHolder: ({vvmHolder}) => vvmHolder,
    vnHolder: ({vnHolder}) => vnHolder,
    vnmHolder: ({vnmHolder}) => vnmHolder,
    vcHolder: ({vcHolder}) => vcHolder,

    amvHolder: ({amvHolder}) => amvHolder,
    amvmHolder: ({amvmHolder}) => amvmHolder,
    amnHolder: ({amnHolder}) => amnHolder,
    amnmHolder: ({amnmHolder}) => amnmHolder,
    amcHolder: ({amcHolder}) => amcHolder,

    intentMeanParentCategoryList: ({intentMeanParentCategoryList}) => intentMeanParentCategoryList,
    
  }
  
  const mutations = {
    [SET_TRAINING_EXAMPLE_COUNT] (state, payload) {
      state.trainingExampleCount = payload
    },

    [SET_HOLDER_CB] (state, payload) {
      var i = (payload.event.target.checked) ? 1 : 0
      state[payload.type+'Holder'][payload.index] = i
    },

    [SET_IMVHOLDER] (state, payload) {
      state.imvHolder[payload.index] = payload.value
    },

    
    [SET_IMVMHOLDER] (state, holder) {
      state.imvmHolder = holder
    },
    [SET_IMNHOLDER] (state, holder) {
      state.imnHolder = holder
    },
    [SET_IMNMHOLDER] (state, holder) {
      state.imnmHolder = holder
    },
    [SET_IMCHOLDER] (state, holder) {
      state.imcHolder = holder
    },

    [SET_EVHOLDER] (state, holder) {
      state.evHolder = holder
    },
    [SET_EVMHOLDER] (state, holder) {
      state.evmHolder = holder
    },
    [SET_ENHOLDER] (state, holder) {
      state.enHolder = holder
    },
    [SET_ENMHOLDER] (state, holder) {
      state.enmHolder = holder
    },
    [SET_ECHOLDER] (state, holder) {
      state.ecHolder = holder
    },

    [SET_VVHOLDER] (state, holder) {
      state.vvHolder = holder
    },
    [SET_VVMHOLDER] (state, holder) {
      state.vvmHolder = holder
    },
    [SET_VNHOLDER] (state, holder) {
      state.vnHolder = holder
    },
    [SET_VNMHOLDER] (state, holder) {
      state.vnmHolder = holder
    },
    [SET_VCHOLDER] (state, holder) {
      state.vcHolder = holder
    },

    [SET_AMVHOLDER] (state, holder) {
      state.amvHolder = holder
    },
    [SET_AMVMHOLDER] (state, holder) {
      state.amvmHolder = holder
    },
    [SET_AMNHOLDER] (state, holder) {
      state.amnHolder = holder
    },
    [SET_AMNMHOLDER] (state, holder) {
      state.amnmHolder = holder
    },
    [SET_AMCHOLDER] (state, holder) {
      state.amcHolder = holder
    },

    [PUSH_INTENT_MEAN_CONTEXT_CATEGORY] (state, context) {
      state.intentMeanContextCategoryList.push(context.name)
    },

    [PUSH_INTENT_MEAN_PARENT_CATEGORY] (state, context) {
      state.intentMeanParentCategoryList.push(context.name)
    },

    [PUSH_END_CONTEXT_CATEGORY] (state, context) {
      state.endContextCategoryList.push(context.name)
    },
    [PUSH_VALUE_CONTEXT_CATEGORY] (state, context) {
      state.valueContextCategoryList.push(context.name)
    },
    [PUSH_ACTION_MEAN_CONTEXT_CATEGORY] (state, context) {
      state.actionMeanContextCategoryList.push(context.name)
    },

    [PUSH_INTENT_MEAN_TAG] (state, tag) {
      state.intentMeanTagList.push(tag.name)
    },
    [PUSH_END_TAG] (state, tag) {
      state.endTagList.push(tag.name)
    },
    [PUSH_VALUE_TAG] (state, tag) {
      state.valueTagList.push(tag.name)
    },
    [PUSH_ACTION_MEAN_TAG] (state, tag) {
      state.actionMeanTagList.push(tag.name)
    },

    [SET_INTENT_MEAN_VALUES] (state, labels) {
      state.imvHolder = labels.verbLabelTensor
      state.imvmHolder = labels.verbModLabelTensor
      state.imnHolder = labels.nounLabelTensor
      state.imnmHolder = labels.nounModLabelTensor
      state.imcHolder = labels.categoryLabelTensor
      state.intentMeanContextCategoryListSelect = labels.contextCategory
      state.intentMeanTagListSelect = []
      state.intentMeanSpecificityLevelListSelect = labels.specificityLevel
      state.intentMeanParentCategoryListSelect = labels.parentCategory
    },
    [SET_END_VALUES] (state, labels) {
      state.evHolder = labels.verbLabelTensor
      state.evmHolder = labels.verbModLabelTensor
      state.enHolder = labels.nounLabelTensor
      state.enmHolder = labels.nounModLabelTensor
      state.ecHolder = labels.categoryLabelTensor
      state.endContextCategoryListSelect = labels.contextCategory
      state.endTagListSelect = []
    },
    [SET_VALUE_VALUES] (state, labels) {
      state.vvHolder = labels.verbLabelTensor
      state.vvmHolder = labels.verbModLabelTensor
      state.vnHolder = labels.nounLabelTensor
      state.vnmHolder = labels.nounModLabelTensor
      state.vcHolder = labels.categoryLabelTensor
      state.valueContextCategoryListSelect = labels.contextCategory
      state.valueTagListSelect = []
    },
    [SET_ACTION_MEAN_VALUES] (state, labels) {
      state.amvHolder = labels.verbLabelTensor
      state.amvmHolder = labels.verbModLabelTensor
      state.amnHolder = labels.nounLabelTensor
      state.amnmHolder = labels.nounModLabelTensor
      state.amcHolder = labels.categoryLabelTensor
      state.actionMeanContextCategoryListSelect = labels.contextCategory
      state.actionMeanTagListSelect = []
      state.actionMeanSpecificityLevelListSelect = labels.specificityLevel
    },

    [SET_INTENT_MEAN_TAGS] (state, labels) {
      state.intentMeanTagListSelect = labels.tagList
    },
    [SET_END_TAGS] (state, labels) {
      state.endTagListSelect = labels.tagList
    },
    [SET_VALUE_TAGS] (state, labels) {
      state.valueTagListSelect = labels.tagList
    },
    [SET_ACTION_MEAN_TAGS] (state, labels) {
      state.actionMeanTagListSelect = labels.tagList
    },

    [RESET_VALUES_AND_TAGS] (state) {
      state.intentMeanTagListSelect = null
      state.intentMeanSpecificityLevelListSelect = null
      state.intentMeanContextCategoryListSelect = null
      state.intentMeanParentCategoryListSelect = null

      state.endTagListSelect = null
      state.endContextCategoryListSelect = null

      state.valueTagListSelect = null
      state.valueContextCategoryListSelect = null

      state.actionMeanTagListSelect = null
      state.actionMeanSpecificityLevelListSelect = null
      state.actionMeanContextCategoryListSelect = null

    }
    
  }
  
  const actions = {
    async getPatternArray({state}, payload) {
      var holder = state[payload.holder+'Holder']
      for(var i = 0; i < holder.length; i++) {
        if(holder[i]) {
          payload.tensor.splice(i,1,1)  //at i index, delete 1 item (a '0') and add 1 item (a '1')
        }
      }
      return payload.tensor
    },
    async getTrainingExampleCount ({commit}) {
      return new Promise((resolve) => {
        (async () => {
          await db.collection('taxonomies').doc('v1_2020_taxonomy').get().then((doc) => {
            var count = doc.data().trainingExampleCount
            commit(SET_TRAINING_EXAMPLE_COUNT, count)
            resolve(count)
          })
        })()
      })
    },
    async changeTagListValue({state}, payload) {
      return new Promise((resolve) => {


        var tagListFieldName
        var tagListCollectionName
        var taxonomyFieldName
        switch(payload.type) {
          case 'intentMean':
            //blah
            tagListFieldName = 'intentMeanTagList'
            tagListCollectionName = 'intent_mean_tags'
            taxonomyFieldName = 'intentMeanTaxonomy'
            break
          case 'end':
            tagListFieldName = 'endTagList'
            tagListCollectionName = 'end_tags'
            taxonomyFieldName = 'endTaxonomy'
            break
          case 'value':
            tagListFieldName = 'valueTagList'
            tagListCollectionName = 'value_tags'
            taxonomyFieldName = 'valueTaxonomy'
            break
          case 'actionMean':
            tagListFieldName = 'actionMeanTagList'
            tagListCollectionName = 'action_mean_tags'
            taxonomyFieldName = 'actionMeanTaxonomy'
            break
        }

        const minderRef = db.collection('v1_2020_minders').doc(payload.id)
        
        //run transaction
        db.runTransaction(t => {
          return Promise.all([t.get(minderRef)])
          //return t.getAll(valueCatRef, minderRef)
          .then(() => {

            var newMinderTagList = []
            newMinderTagList = payload.event

            t.set(minderRef,{
              [taxonomyFieldName]: {
                [tagListFieldName]: newMinderTagList
              }
            },{merge: true})

          }).then(() => {
            payload.event.forEach(function(tag) {
              var tag2 = tag.replace(/[:;,]/g, '')
              tag2 = tag2.trim()
              if(!state[tagListFieldName].includes(tag2)) {
                  db.collection('taxonomies').doc('v1_2020_taxonomy').collection(tagListCollectionName).doc(tag2).set({'name': tag2})
              }
            })
            return Promise.resolve();
          })
        })
        resolve()
      })
     
    },
    async changeSpecificityLevelValue(_, payload) {
      return new Promise((resolve) => {
        (async () => {
          var specificityLevelFieldName = null
          var taxonomyFieldName = null
          switch(payload.type) {
            case 'intentMean':
              //blah
              specificityLevelFieldName = 'intentMeanSpecificityLevel'
              taxonomyFieldName = 'intentMeanTaxonomy'
              break;
            /*case 'end':
              specificityLevelFieldName = 'endSpecificityLevel'
              taxonomyFieldName = 'endTaxonomy'
            case 'value':
              specificityLevelFieldName = 'valueSpecificityLevel'
              taxonomyFieldName = 'valueTaxonomy' */
            case 'actionMean':
              specificityLevelFieldName = 'actionMeanSpecificityLevel'
              taxonomyFieldName = 'actionMeanTaxonomy'
              break;

              
          }

          if((payload.type === 'intentMean') || (payload.type === 'actionMean')) {
            await db.collection('v1_2020_minders').doc(payload.id).set({
              [taxonomyFieldName]: {
                [specificityLevelFieldName]: payload.event
              }
            }, {merge: true})
    
          }
          resolve()

        })()
      })
    },
    async changeContextCategoryValue(_, payload) {
      return new Promise((resolve) => {
        (async () => {
          var contextCategoryFieldName
          switch(payload.type) {
            case 'intentMean':
              //blah
              contextCategoryFieldName = 'intentMeanContextCategory';
              break;
            case 'end':
              contextCategoryFieldName = 'endContextCategory';
              break;
            case 'value':
              contextCategoryFieldName = 'valueContextCategory';
              break;
            case 'actionMean':
              contextCategoryFieldName = 'actionMeanContextCategory';
              break;
            default:
              
            
          }


          await db.collection('v1_2020_minders').doc(payload.id).set({
            [contextCategoryFieldName]: payload.event
          }, {merge: true})

          resolve()

        })()

      })
    },

    async changeParentCategoryValue(_, payload) {
      return new Promise((resolve) => {
        (async () => {
          var parentCategoryFieldName
          switch(payload.type) {
            case 'intentMean':
              //blah
              parentCategoryFieldName = 'intentMeanParentCategory';
              break;
            case 'end':
              parentCategoryFieldName = 'endParentCategory';
              break;
            case 'value':
              parentCategoryFieldName = 'valueParentCategory';
              break;
            case 'actionMean':
              parentCategoryFieldName = 'actionMeanParentCategory';
              break;
            default:
              
            
          }

         

          await db.collection('v1_2020_minders').doc(payload.id).set({
            [parentCategoryFieldName]: payload.event
          }, {merge: true})

          resolve()

        })()
      })
    },

    async changeCbValue({commit,state}, payload) {
      
      commit(SET_HOLDER_CB, payload)
      var patternLabelFieldName = null
      var taxLabelTensorFieldName = null
      if((payload.type === 'imv') || (payload.type === 'imvm') || (payload.type === 'imn') || (payload.type === 'imnm') || (payload.type === 'imc')) {
        patternLabelFieldName = 'intentMeanPatternLabels'
      }
      if((payload.type === 'ev') || (payload.type === 'evm') || (payload.type === 'en') || (payload.type === 'enm') || (payload.type === 'ec')) {
        patternLabelFieldName = 'endPatternLabels'
      }
      if((payload.type === 'vv') || (payload.type === 'vvm') || (payload.type === 'vn') || (payload.type === 'vnm') || (payload.type === 'vc')) {
        patternLabelFieldName = 'valuePatternLabels'
      }
      if((payload.type === 'amv') || (payload.type === 'amvm') || (payload.type === 'amn') || (payload.type === 'amnm') || (payload.type === 'amc')) {
        patternLabelFieldName = 'actionMeanPatternLabels'
      }


      if((payload.type === 'imv') || (payload.type === 'ev') || (payload.type === 'vv') || (payload.type === 'amv')) {
        taxLabelTensorFieldName = 'verbLabelTensor'
      }
      if((payload.type === 'imvm') || (payload.type === 'evm') || (payload.type === 'vvm') || (payload.type === 'amvm')) {
        taxLabelTensorFieldName = 'verbModLabelTensor'
      }
      if((payload.type === 'imn') || (payload.type === 'en') || (payload.type === 'vn') || (payload.type === 'amn')) {
        taxLabelTensorFieldName = 'nounLabelTensor'
      }
      if((payload.type === 'imnm') || (payload.type === 'enm') || (payload.type === 'vnm') || (payload.type === 'amnm')) {
        taxLabelTensorFieldName = 'nounModLabelTensor'
      }
      if((payload.type === 'imc') || (payload.type === 'ec') || (payload.type === 'vc') || (payload.type === 'amc')) {
        taxLabelTensorFieldName = 'categoryLabelTensor'
      }


      //commit(SET_HOLDER_CB, {'index': payload.index, 'type': payload.type, 'value': payload.event.target.checked})
      await db.collection('v1_2020_minders').doc(payload.id).set({
        [patternLabelFieldName]: {
          [taxLabelTensorFieldName]: state[payload.type+'Holder']
        }
      }, {merge: true})
    },
    
    setIntentMeanValues ({commit}, payload) {
      commit(SET_INTENT_MEAN_VALUES, payload)
    },
    setEndValues ({commit}, payload) {
      commit(SET_END_VALUES, payload)
    },
    setValueValues ({commit}, payload) {
      commit(SET_VALUE_VALUES, payload)
    },
    setActionMeanValues ({commit}, payload) {
      commit(SET_ACTION_MEAN_VALUES, payload)
    },
    setIntentMeanTags ({commit}, payload) {
      commit(SET_INTENT_MEAN_TAGS, payload)
    },
    setEndTags ({commit}, payload) {
      commit(SET_END_TAGS, payload)
    },
    setValueTags ({commit}, payload) {
      commit(SET_VALUE_TAGS, payload)
    },
    setActionMeanTags ({commit}, payload) {
      commit(SET_ACTION_MEAN_TAGS, payload)
    },
    resetValuesAndTags ({commit}) {
      commit(RESET_VALUES_AND_TAGS)
    },
    async createPatternInFirestore (_, payload) {
      return new Promise((resolve) => {
        (async () => {

          var ref = db.collection('taxonomies').doc('v1_2020_taxonomy').collection('v1_2020_patterns').doc(payload.pos + '_' + payload.dep)
          await ref.set({
            'v': payload.v,
            'n': payload.n,
            'nm': payload.nm,
            'vm': payload.vm,
            'c': payload.c,
            'depPattern': payload.dep,
            'posPattern': payload.pos,
            'depLabelPattern': payload.depLabel,
            'dateCreated': new Date()
          }, {merge: true})
          resolve()

        })()
      })
    },

    async updateContextCategories (_, data) {
      var contextCategory = data.contextCategoryListSelect.replace(/[:;,]/g, '')
      contextCategory = contextCategory.trim()

      var taxonomyFieldName = null
      var contextCategoryFieldName = null
      var specificityLevelFieldName = null
      var tagListFieldName = null
      if(data.statementType === 'intentMean') {
        taxonomyFieldName = 'intentMeanTaxonomy'
        contextCategoryFieldName = 'intentMeanContextCategory'
        specificityLevelFieldName = 'intentMeanSpecificityLevel'
        tagListFieldName = 'intentMeanTagList'
      }
      if(data.statementType === 'end') {
        taxonomyFieldName = 'endTaxonomy'
        contextCategoryFieldName = 'endContextCategory'
        specificityLevelFieldName = 'endSpecificityLevel'
        tagListFieldName = 'endTagList'
      }
      if(data.statementType === 'value') {
        taxonomyFieldName = 'valueTaxonomy'
        contextCategoryFieldName = 'valueContextCategory'
        specificityLevelFieldName = 'valueSpecificityLevel'
        tagListFieldName = 'valueTagList'
      }
      if(data.statementType === 'actionMean') {
        taxonomyFieldName = 'actionMeanTaxonomy'
        contextCategoryFieldName = 'actionMeanContextCategory'
        specificityLevelFieldName = 'actionMeanSpecificityLevel'
        tagListFieldName = 'actionMeanTagList'
      }

      const minderRef = db.collection('v1_2020_minders').doc(data.minderId)
      
    
      db.runTransaction(t => {
        return Promise.all([t.get(minderRef)])
          .then(() => {

            //Update minder
            t.set(minderRef,{
                [taxonomyFieldName]: {
                  [contextCategoryFieldName]: contextCategory,
                  [tagListFieldName]: data.tagListSelect
                }
            },{merge: true})

            //Only intentMean and actionMean have a specificityLevel
            if((data.statementType === 'intentMean') || (data.statementType === 'actionMean')) {
              t.set(minderRef,{
                  [taxonomyFieldName]: {
                    [specificityLevelFieldName]: data.specificityLevelSelect,
                  }
              },{merge: true})
            }


            return Promise.resolve()

          }).catch(function(err) {
            /* eslint-disable no-console */
            console.error(err.message)
          })

        }).then(() => {

        }).catch(err => {
          /* eslint-disable no-console */
          console.log('Error in updateContextCategories()', err)
        })


      
      /*console.log("tagListSelect.length: ",tagListSelect.length )
      //transaction number two
      if(tagListSelect.length > 0) {
        const taxonomyContextCatRef = db.collection('taxonomies').doc('v1_2020_taxonomy').collection(contextCategoryCollectionName).doc(contextCategory)
        const taxonomyRef = db.collection('taxonomies').doc('v1_2020_taxonomy')
        tagListSelect.forEach((tag) => {
          //get the tags for the context category
          db.runTransaction(trans => {
            return Promise.all([trans.get(taxonomyContextCatRef), trans.get(taxonomyRef)])
              .then(theDocs => {
                var contextCatDoc = theDocs[0]
                var taxDoc = theDocs[1]

                var oldTagList = contextCatDoc.data().tags

                //check if this tag is in the oldTagList, if not, add it new and set its usageCount to 1, if not new, update the usageCount

                if(!oldTagList.includes(tag)) {
                  //add it new and set its usageCount to 1
                  trans.set(taxonomyContextCatRef, {
                    tags: {
                      [tag]: {
                        'usageCount': 1
                      }
                    }
                  }, {merge: true})



                }else {
                  //update the usageCount
                  var newCount = oldTagList[tag].usageCount + 1
                  trans.set(taxonomyContextRef, {
                    tags: {
                      [tag]: {
                        'usageCount': newCount,
                      }
                    }
                  })

                }

              


              }).catch(function(err) {
                console.error(err.message)
              })
          }).then(result => {

          }).catch(err => {
            console.log('Error in updateContextCategories()', err)
          })

          
        })
      }*/


      //const taxonomyContextCatRef = db.collection('taxonomies').doc('v1_2020_taxonomy').collection(contextCategoryCollectionName).doc(contextCategory)
      //const taxonomyTagListRef = db.collection('taxonomies').doc('v1_2020_taxonomy').collection(tagListCollectionName).doc(tagListSelect)
      //const taxonomyRef = db.collection('taxonomies').doc('v1_2020_taxonomy')

            //if a new tag, add to firestore - NEED TO REWRITE AND WRAP WITH A FOREACH SINCE tagListSelect is now an array and not a string
            /*if(!state.intentMeanTagList.includes(tagListSelect)) {
              t.set(taxonomyTagListRef, {'tagName': tagListSelect}, {merge: true})
              //update taxonomy
              var newTagCount = taxonomyDoc.data().tagCountFieldName + 1
              t.set(taxonomyRef, {
                [tagCountFieldName]: newTagCount
              }, {merge: true})
            }*/





      return Promise.resolve();
  },
  

    async deleteStatements (_, payload) {
      return new Promise(function(resolve) {
        db.collection('v1_2020_statements').doc(payload.intentMeanStatementId).delete()
        db.collection('v1_2020_statements').doc(payload.endStatementId).delete()
        db.collection('v1_2020_statements').doc(payload.valueStatementId).delete()
        db.collection('v1_2020_statements').doc(payload.actionMeanStatementId).delete()
        resolve()
      })
    },




    async createStatements ({dispatch}, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {

            try {
                var newDate = new Date()

            // create intent mean statement
            await dispatch('statement/createStatement', {
                'dateCreated': newDate,
                'dateUpdated': newDate,
                'minderId': payload.minderId,
                'momentId': null,
                'statementOriginalText': payload.intentMeanStatementText,
                'statementStimulusText': payload.intentMeanStatementStimulusText,
                'statementType': 'intentMean',
                'statementId': payload.intentMeanStatementId,
              } , {root: true})


            // create end statement
            await dispatch('statement/createStatement', {
                'dateCreated': newDate,
                'dateUpdated': newDate,
                'minderId': payload.minderId,
                'momentId': null,
                'statementOriginalText': payload.endStatementText,
                'statementStimulusText': payload.endStatementStimulusText,
                'statementType': 'end',
                'statementId': payload.endStatementId,
              } , {root: true})

            
            // create value statement
              await dispatch('statement/createStatement', {
                'dateCreated': newDate,
                'dateUpdated': newDate,
                'minderId': payload.minderId,
                'momentId': null,
                'statementOriginalText': payload.valueStatementText,
                'statementStimulusText': payload.valueStatementStimulusText,
                'statementType': 'value',
                'statementId': payload.valueStatementId,
              } , {root: true})


            // create action mean statement
            await dispatch('statement/createStatement', {
                'dateCreated': newDate,
                'dateUpdated': newDate,
                'minderId': payload.minderId,
                'momentId': null,
                'statementOriginalText': payload.actionMeanStatementText,
                'statementStimulusText': payload.actionMeanStatementStimulusText,
                'statementType': 'actionMean',
                'statementId': payload.actionMeanStatementId,
              } , {root: true})

            //update the minder's statement text based on new, edited statements, ALSO initialize the tagLists
            await dispatch('minder/updateMinderStatements', {
                'dateUpdated': newDate,
                'minderId': payload.minderId,
                'intentMeanStatementStimulusText': payload.intentMeanStatementStimulusText,
                'intentMeanStatementText': payload.intentMeanStatementText,
                'intentMeanTaxonomy': {
                  'intentMeanTagList': []
                },
                'intentMeanContextCategory': null,
                'intentMeanParentCategory': null,

                'endStatementStimulusText': payload.endStatementStimulusText,
                'endStatementText': payload.endStatementText,
                'endTaxonomy': {
                  'endTagList': []
                },
                'endContextCategory': null,

                'valueStatementStimulusText': payload.valueStatementStimulusText,
                'valueStatementText': payload.valueStatementText,
                'valueTaxonomy': {
                  'valueTagList': []
                },
                'valueContextCategory': null,

                'actionMeanStatementStimulusText': payload.actionMeanStatementStimulusText,
                'actionMeanStatementText': payload.actionMeanStatementText,
                'actionMeanTaxonomy': {
                  'actionMeanTagList': []
                },
                'actionMeanContextCategory': null,
              } , {root: true})
            } catch(e) {
                /* eslint-disable no-console */
                console.log("Error: ", e)
            }
            
            



            resolve()
          } else {
            reject()
          }
        })
      })
    },
    async getIntentMeanContextCategories ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_context_categories').onSnapshot(snap => {
        snap.forEach((doc) => {
          commit(PUSH_INTENT_MEAN_CONTEXT_CATEGORY, {
            ...doc.data()
          })
        })
      })
    },

    async getIntentMeanParentCategories ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_parent_categories').onSnapshot(snap => {
        snap.forEach((doc) => {
          commit(PUSH_INTENT_MEAN_PARENT_CATEGORY, {
            ...doc.data()
          })
        })
      })
    },

    async getIntentMeanTags ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_tags').onSnapshot(snap => {
        snap.forEach((doc) => {
          commit(PUSH_INTENT_MEAN_TAG, {
            ...doc.data()
          })
        })
      })
    },

    async getEndContextCategories ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('end_context_categories').onSnapshot(snap => {
        snap.forEach((doc) =>{
          commit(PUSH_END_CONTEXT_CATEGORY, {
            ...doc.data()
          })
        })
      })
    },
  
    async getEndTags ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('end_tags').onSnapshot(snap => {
        snap.forEach((doc) => {
          commit(PUSH_END_TAG, {
            ...doc.data()
          })
        })
      })
    },

    async getValueContextCategories ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('value_context_categories').onSnapshot(snap => {
        snap.forEach((doc) =>{
          commit(PUSH_VALUE_CONTEXT_CATEGORY, {
            ...doc.data()
          })
        })
      })
    },
  
    async getValueTags ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('value_tags').onSnapshot(snap => {
        snap.forEach((doc) => {
          commit(PUSH_VALUE_TAG, {
            ...doc.data()
          })
        })
      })
    },

    async getActionMeanContextCategories ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('action_mean_context_categories').onSnapshot(snap => {
        snap.forEach((doc) => {
          commit(PUSH_ACTION_MEAN_CONTEXT_CATEGORY, {
            ...doc.data()
          })
        })
      })
    },

    async getActionMeanTags ({commit}) {
      await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('action_mean_tags').onSnapshot(snap => {
        snap.forEach((doc) => {
          commit(PUSH_ACTION_MEAN_TAG, {
            ...doc.data()
          })
        })
      })
    },

    
    

  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }