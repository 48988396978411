export const PUSH_INTENT_MEAN_CONTEXT_CATEGORY = 'PUSH_INTENT_MEAN_CONTEXT_CATEGORY'
export const PUSH_END_CONTEXT_CATEGORY = 'PUSH_END_CONTEXT_CATEGORY'
export const PUSH_VALUE_CONTEXT_CATEGORY = 'PUSH_VALUE_CONTEXT_CATEGORY'
export const PUSH_ACTION_MEAN_CONTEXT_CATEGORY = 'PUSH_ACTION_MEAN_CONTEXT_CATEGORY'

export const PUSH_INTENT_MEAN_PARENT_CATEGORY = 'PUSH_INTENT_MEAN_PARENT_CATEGORY'

export const PUSH_INTENT_MEAN_TAG = 'PUSH_INTENT_MEAN_TAG'
export const PUSH_END_TAG = 'PUSH_END_TAG'
export const PUSH_VALUE_TAG = 'PUSH_VALUE_TAG'
export const PUSH_ACTION_MEAN_TAG = 'PUSH_ACTION_MEAN_TAG'

export const SET_HOLDER_CB = 'SET_HOLDER_CB'

export const SET_IMVHOLDER = 'SET_IMVHOLDER'
export const SET_IMVMHOLDER = 'SET_IMVMHOLDER'
export const SET_IMNHOLDER = 'SET_IMNHOLDER'
export const SET_IMNMHOLDER = 'SET_IMNMHOLDER'
export const SET_IMCHOLDER = 'SET_IMCHOLDER'

export const SET_EVHOLDER = 'SET_EVHOLDER'
export const SET_EVMHOLDER = 'SET_EVMHOLDER'
export const SET_ENHOLDER = 'SET_ENHOLDER'
export const SET_ENMHOLDER = 'SET_ENMHOLDER'
export const SET_ECHOLDER = 'SET_ECHOLDER'

export const SET_VVHOLDER = 'SET_VVHOLDER'
export const SET_VVMHOLDER = 'SET_VVMHOLDER'
export const SET_VNHOLDER = 'SET_VNHOLDER'
export const SET_VNMHOLDER = 'SET_VNMHOLDER'
export const SET_VCHOLDER = 'SET_VCHOLDER'

export const SET_AMVHOLDER = 'SET_AMVHOLDER'
export const SET_AMVMHOLDER = 'SET_AMVMHOLDER'
export const SET_AMNHOLDER = 'SET_AMNHOLDER'
export const SET_AMNMHOLDER = 'SET_AMNMHOLDER'
export const SET_AMCHOLDER = 'SET_AMCHOLDER'

export const SET_INTENT_MEAN_VALUES = 'SET_INTENT_MEAN_VALUES'
export const SET_END_VALUES = 'SET_END_VALUES'
export const SET_VALUE_VALUES = 'SET_VALUE_VALUES'
export const SET_ACTION_MEAN_VALUES = 'SET_ACTION_MEAN_VALUES'

export const SET_INTENT_MEAN_TAGS = 'SET_INTENT_MEAN_TAGS'
export const SET_END_TAGS = 'SET_END_TAGS'
export const SET_VALUE_TAGS = 'SET_VALUE_TAGS'
export const SET_ACTION_MEAN_TAGS = 'SET_ACTION_MEAN_TAGS'

export const SET_TRAINING_EXAMPLE_COUNT = 'SET_TRAINING_EXAMPLE_COUNT'

export const RESET_VALUES_AND_TAGS = 'RESET_VALUES_AND_TAGS'