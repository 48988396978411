<template>
<v-app>
  <div class="moderationDashboardWrapper">
    <div class="dashCard">
      <div class="dashValue" :key="trainingExampleCount">
        {{ trainingExampleCount }}
      </div>
      <div class="dashValueLabel">
        Completed
      </div>
    </div>
    <div class="dashCard">
      <div class="dashValue">
        700
      </div>
      <div class="dashValueLabel">
        Goal
      </div>
    </div>
  </div>
  <v-data-table
    :headers="headers"
    :items="minders"
    hide-actions
    :expanded.sync="expanded"
    item-key="id"
    ref="dTable"
    disable-initial-sort
    hide-headers
    :loading="showSpinner"
  >
     <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
    <template slot="items" slot-scope="props">
      {{ props.item.minderId }}
      <div class="mev_item_wrapper">

        <div class="left_rail">
          &nbsp;
        </div>
        
        <div class="chainWrapper">
          <div class="chain_box">
            <i class="fas fa-crosshairs statementBullet"></i><span class="statementTitle"><b>Intent Mean:</b></span>&nbsp;&nbsp;&nbsp;
            <img v-if="((intentMeanStatementBeingAnalyzed) && (props.item.intentMeanStatementStatus === 'just created'))" 
                 v-bind:src="require('@/assets/spinner.webp')" 
                 height="16" 
                 alt="spinner" 
                 id="spinner">
            <img v-else-if="props.item.intentMeanStatementStatus === 'ready to process'"
                 v-bind:src="require('@/assets/greencheckmark.jpg')" 
                 height="16" 
                 alt="readyCheckmark" 
                 id="readyCheckmark">
            
            <v-text-field
                ref="intentMeanStatementTextFieldRef"
                name="intentMeanStatementTextField"
                id="intentMeanStatementTextField"
                type="text"
                :value="props.item.intentMeanStatementText">
              </v-text-field>            
          </div>
        
          <div class="chain_box">
            <i class="fas fa-crosshairs statementBullet"></i><span class="statementTitle"><b>End (purpose):</b></span>&nbsp;&nbsp;&nbsp;
            <img v-if="((endStatementBeingAnalyzed) && (props.item.endStatementStatus === 'just created'))" 
                 v-bind:src="require('@/assets/spinner.webp')" 
                 height="16" 
                 alt="spinner" 
                 id="spinner">
            <img v-else-if="props.item.intentMeanStatementStatus === 'ready to process'"
                 v-bind:src="require('@/assets/greencheckmark.jpg')" 
                 height="16" 
                 alt="readyCheckmark" 
                 id="readyCheckmark">
            <v-text-field
                ref="endStatementTextFieldRef"
                name="endStatementTextField"
                id="endStatementTextField"
                type="text"
                :value="props.item.endStatementText">
              </v-text-field>
          </div>
          
          <div class="chain_box">
            <i class="fas fa-crosshairs statementBullet"></i><span class="statementTitle"><b>Values (consequences):</b></span>&nbsp;&nbsp;&nbsp;
            <img v-if="((valueStatementBeingAnalyzed) && (props.item.valueStatementStatus === 'just created'))" 
                 v-bind:src="require('@/assets/spinner.webp')" 
                 height="16" 
                 alt="spinner" 
                 id="spinner">
            <img v-else-if="props.item.intentMeanStatementStatus === 'ready to process'"
                 v-bind:src="require('@/assets/greencheckmark.jpg')" 
                 height="16" 
                 alt="readyCheckmark" 
                 id="readyCheckmark">
            <v-text-field
                ref="valueStatementTextFieldRef"
                name="valueStatementTextField"
                id="valueStatementTextField"
                type="text"
                :value="props.item.valueStatementText">
              </v-text-field>
          </div>

          <div class="chain_box">
            <i class="fas fa-crosshairs statementBullet"></i><span class="statementTitle"><b>Action Mean (next step):</b></span>&nbsp;&nbsp;&nbsp;
            <img v-if="((actionMeanStatementBeingAnalyzed) && (props.item.actionMeanStatementStatus === 'just created'))" 
                 v-bind:src="require('@/assets/spinner.webp')" 
                 height="16" 
                 alt="spinner" 
                 id="spinner">
            <img v-else-if="props.item.intentMeanStatementStatus === 'ready to process'"
                 v-bind:src="require('@/assets/greencheckmark.jpg')" 
                 height="16" 
                 alt="readyCheckmark" 
                 id="readyCheckmark">
            <v-text-field
                ref="actionMeanStatementTextFieldRef"
                name="actionMeanStatementTextField"
                id="actionMeanStatementTextField"
                type="text"
                :value="props.item.actionMeanStatementText">
              </v-text-field>
          
          
          
          
          </div>
        </div>
        <div class="right_rail">
          <div id="redoMinderIcon" @click="redoTheMinder(props.item)">
            <i class="fas fa-redo"></i>
          </div>
          <div id="deleteMinderIcon" @click="remove(props.item)">
            <i class="far fa-trash-alt"></i>
          </div>
          <div id="closeMinderIcon" @click="closeTheMinder(props.item)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </div> 
      <br />
      <v-btn @click="reanalyzeStatements(props.item)" color="lightgrey" type="submit" v-if="props.item.intentMeanStatementStatus === 'ready to process'">Re-analyze Statements</v-btn>
      <br />
      <br />
      <br />
      <br />



      <!-- INTENT MEAN STATEMENT -->
      <div v-if="props.item.intentMeanStatementStatus === 'ready to process'">
        <div class="blockTitle">
          Intent Mean Statement
        </div>
        <div class="statementBlock">
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix words" v-for="(word, index) in props.item.intentMeanStatementPatterns.statementWordPatternArray" align="center" v-bind:key="'intentMean'+word+index">
              <span style="font-size: .90em; font-weight: 400;">{{ index }}</span><br />{{ word }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(pos, index) in props.item.intentMeanStatementPatterns.statementPosPatternArray" align="center" v-bind:key="'intentMean'+pos+index">
              {{ pos }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(label, index) in props.item.intentMeanStatementPatterns.statementLabelPatternArray" align="center" v-bind:key="'intentMean'+label+index">
              {{ label }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(dep, index) in props.item.intentMeanStatementPatterns.statementDepPatternArray" align="center" v-bind:key="'intentMean'+dep+index">
              {{ dep }}
            </div>
          </div>
          <br />

          <div v-if="props.item.intentMeanStatementTaxonomyStatus === 'needs moderation'"> 
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Main Verb:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.intentMeanPatternLabels.verbLabelTensor" align="center" v-bind:key="'imv'+index">
                <div class="cbContainer">
                  <!--<input type="checkbox" class="thecheckbox" v-model="imvHolder[index]">-->
                  <input type="checkbox" class="thecheckbox" :checked="imvHolder[index]" @change="e => changeCbValue('imv', index, e, props.item.minderId)">
                </div>
              </div>
              <div class="matrixWrapper">
                <div class="leftRailMatrix">
                  Verb Modifier:
                </div>
                <div class="wordMatrix" v-for="(word, index) in props.item.intentMeanPatternLabels.verbModLabelTensor" align="center" v-bind:key="'imvm'+index">
                  <div class="cbContainer">
                    <input type="checkbox" class="thecheckbox" :checked="imvmHolder[index]" @change="e => changeCbValue('imvm', index, e, props.item.minderId)">
                  </div>
                </div>
              </div>
              <div class="matrixWrapper">
                <div class="leftRailMatrix">
                  Main Noun:
                </div>
                <div class="wordMatrix" v-for="(word, index) in props.item.intentMeanPatternLabels.nounLabelTensor" align="center" v-bind:key="'imn'+index">
                  <div class="cbContainer">
                    <input type="checkbox" class="thecheckbox" :checked="imnHolder[index]" @change="e => changeCbValue('imn', index, e, props.item.minderId)">
                  </div>
                </div>
              </div>
              <div class="matrixWrapper">
                <div class="leftRailMatrix">
                  Noun Modifier:
                </div>
                <div class="wordMatrix" v-for="(word, index) in props.item.intentMeanPatternLabels.nounModLabelTensor" align="center" v-bind:key="'imnm'+word+index">
                  <div class="cbContainer">
                    <input type="checkbox" class="thecheckbox" :checked="imnmHolder[index]" @change="e => changeCbValue('imnm', index, e, props.item.minderId)">
                  </div>
                </div>
              </div>
              <div class="matrixWrapper">
                <div class="leftRailMatrix">
                  Category (Verb + Noun):
                </div>
                <div class="wordMatrix" v-for="(word, index) in props.item.intentMeanPatternLabels.categoryLabelTensor" align="center" v-bind:key="'imc'+word+index">
                  <div class="cbContainer">
                    <input type="checkbox" class="thecheckbox" :checked="imcHolder[index]" @change="e => changeCbValue('imc', index, e, props.item.minderId)">
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div v-else>



          </div>
          <br />
          <br />
          <div class="comboBoxWrapper">
            <v-combobox
              :value="intentMeanContextCategoryListSelect"               
              :items="intentMeanContextCategoryList"
              label="Select a context category, or add a new one"
              @change="e => changeContextCategoryValue('intentMean', e, props.item.minderId)"
              v-bind:key="'intentMeanContextCategory'"
            ></v-combobox>
            <br />
            <v-combobox
              :value="intentMeanSpecificityLevelListSelect"
              :items="intentMeanSpecificityLevelList"
              @change="e => changeSpecificityLevelValue('intentMean', e, props.item.minderId)"
              label="General or Specific"
              v-bind:key="'intentMeanSpecificityLevel'">
            </v-combobox>
            <br />
            <v-combobox
              :value="intentMeanTagListSelect"               
              :items="intentMeanTagList"
              @change="e => changeTagListValue('intentMean', e, props.item.minderId)"
              label="Select a tag, or add a new one"
              v-bind:key="'intentMeanTagList'"
              multiple
            ></v-combobox>
            <br />
            <v-combobox
              :value="intentMeanParentCategoryListSelect"               
              :items="intentMeanParentCategoryList"
              @change="e => changeParentCategoryValue('intentMean', e, props.item.minderId)"
              label="Select a parent intent mean category, or add a new one"
              v-bind:key="'intentMeanParentCategoryList'"
            ></v-combobox>
            <br />

          </div>
        </div>
      </div>


      <!-- END STATEMENT -->
      <div v-if="props.item.endStatementStatus === 'ready to process'">
        <div class="blockTitle">
          End Statement
        </div>
        <div class="statementBlock">
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix words" v-for="(word, index) in props.item.endStatementPatterns.statementWordPatternArray" align="center" v-bind:key="'end'+word+index">
              <span style="font-size: .90em; font-weight: 500;">{{ index }}</span><br />{{ word }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(pos, index) in props.item.endStatementPatterns.statementPosPatternArray" align="center" v-bind:key="'end'+pos+index">
              {{ pos }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(label, index) in props.item.endStatementPatterns.statementLabelPatternArray" align="center" v-bind:key="'end'+label+index">
              {{ label }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(dep, index) in props.item.endStatementPatterns.statementDepPatternArray" align="center" v-bind:key="'end'+dep+index">
              {{ dep }}
            </div>
          </div>
          <br />

          <div v-if="props.item.endStatementTaxonomyStatus === 'needs moderation'">
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Main Verb:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.endPatternLabels.verbLabelTensor" align="center" v-bind:key="'ev'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="evHolder[index]" @change="e => changeCbValue('ev', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Verb Modifier:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.endPatternLabels.verbModLabelTensor" align="center" v-bind:key="'evm'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="evmHolder[index]" @change="e => changeCbValue('evm', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Main Noun:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.endPatternLabels.nounLabelTensor" align="center" v-bind:key="'en'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="enHolder[index]" @change="e => changeCbValue('en', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Noun Modifier:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.endPatternLabels.nounModLabelTensor" align="center" v-bind:key="'enm'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="enmHolder[index]" @change="e => changeCbValue('enm', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Category (Verb + Noun):
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.endPatternLabels.categoryLabelTensor" align="center" v-bind:key="'ec'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="ecHolder[index]" @change="e => changeCbValue('ec', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
          </div>
          <div v-else>



          </div>
          <br />
          <br />
          <div class="comboBoxWrapper">
            <v-combobox
              :value="endContextCategoryListSelect"
              @change="e => changeContextCategoryValue('end', e, props.item.minderId)"               
              :items="endContextCategoryList"
              label="Select a context category, or add a new one"
            ></v-combobox>
            <br />
            
            <v-combobox
              :value="endTagListSelect"               
              :items="endTagList"
              @change="e => changeTagListValue('end', e, props.item.minderId)"
              label="Select a tag, or add a new one"
              v-bind:key="'endTagList'"
              multiple
            ></v-combobox>
            <br />
          </div>
        </div>
      </div>




      <!-- VALUE STATEMENT -->
      <div v-if="props.item.valueStatementStatus === 'ready to process'">
        <div class="blockTitle">
          Value Statement
        </div>
        <div class="statementBlock">
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix words" v-for="(word, index) in props.item.valueStatementPatterns.statementWordPatternArray" align="center" v-bind:key="'value'+word+index">
              <span style="font-size: .90em; font-weight: 400;">{{ index }}</span><br />{{ word }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(pos, index) in props.item.valueStatementPatterns.statementPosPatternArray" align="center" v-bind:key="'value'+pos+index">
              {{ pos }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(label, index) in props.item.valueStatementPatterns.statementLabelPatternArray" align="center" v-bind:key="'value'+label+index">
              {{ label }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(dep, index) in props.item.valueStatementPatterns.statementDepPatternArray" align="center" v-bind:key="'value'+dep+index">
              {{ dep }}
            </div>
          </div>
          <br />

          <div v-if="props.item.valueStatementTaxonomyStatus === 'needs moderation'"> 
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Main Verb:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.valuePatternLabels.verbLabelTensor" align="center" v-bind:key="'vv'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="vvHolder[index]" @change="e => changeCbValue('vv', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Verb Modifier:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.valuePatternLabels.verbModLabelTensor" align="center" v-bind:key="'vvm'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="vvmHolder[index]" @change="e => changeCbValue('vvm', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Main Noun:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.valuePatternLabels.nounLabelTensor" align="center" v-bind:key="'vn'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="vnHolder[index]" @change="e => changeCbValue('vn', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Noun Modifier:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.valuePatternLabels.nounModLabelTensor" align="center" v-bind:key="'vnm'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="vnmHolder[index]" @change="e => changeCbValue('vnm', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Category (Verb + Noun):
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.valuePatternLabels.categoryLabelTensor" align="center" v-bind:key="'vc'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="vcHolder[index]" @change="e => changeCbValue('vc', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
          </div>
          <div v-else>



          </div>
          <br />
          <br />
          <div class="comboBoxWrapper">
            <v-combobox
              :value="valueContextCategoryListSelect"               
              :items="valueContextCategoryList"
              @change="e => changeContextCategoryValue('value', e, props.item.minderId)"
              label="Select a context category, or add a new one"
            ></v-combobox>
            <br />
            
            <v-combobox
              :value="valueTagListSelect"      
              :items="valueTagList"
              @change="e => changeTagListValue('value', e, props.item.minderId)"
              label="Select a tag, or add a new one"
              v-bind:key="'valueTagList'"
              multiple
            ></v-combobox>
            <br />
          </div>
        </div>
      </div>



      <!-- ACTION MEAN STATEMENT -->
      <div v-if="props.item.actionMeanStatementStatus === 'ready to process'">
        <div class="blockTitle">
          Action Mean Statement
        </div>
        <div class="statementBlock">
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix words" v-for="(word, index) in props.item.actionMeanStatementPatterns.statementWordPatternArray" align="center" v-bind:key="'actionMean'+word+index">
              <span style="font-size: .90em; font-weight: 400;">{{ index }}</span><br />{{ word }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(pos, index) in props.item.actionMeanStatementPatterns.statementPosPatternArray" align="center" v-bind:key="'actionMean'+pos+index">
              {{ pos }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(label, index) in props.item.actionMeanStatementPatterns.statementLabelPatternArray" align="center" v-bind:key="'actionMean'+label+index">
              {{ label }}
            </div>
          </div>
          <div class="matrixWrapper">
            <div class="leftRailMatrix">
              &nbsp;
            </div>
            <div class="wordMatrix" v-for="(dep, index) in props.item.actionMeanStatementPatterns.statementDepPatternArray" align="center" v-bind:key="'actionMean'+dep+index">
              {{ dep }}
            </div>
          </div>
          <br />

          <div v-if="props.item.actionMeanStatementTaxonomyStatus === 'needs moderation'">
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Main Verb:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.actionMeanPatternLabels.verbLabelTensor" align="center" v-bind:key="'amv'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="amvHolder[index]" @change="e => changeCbValue('amv', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Verb Modifier:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.actionMeanPatternLabels.verbModLabelTensor" align="center" v-bind:key="'amvm'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="amvmHolder[index]" @change="e => changeCbValue('amvm', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Main Noun:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.actionMeanPatternLabels.nounLabelTensor" align="center" v-bind:key="'amn'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="amnHolder[index]" @change="e => changeCbValue('amn', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Noun Modifier:
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.actionMeanPatternLabels.nounModLabelTensor" align="center" v-bind:key="'amnm'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="amnmHolder[index]" @change="e => changeCbValue('amnm', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
            <div class="matrixWrapper">
              <div class="leftRailMatrix">
                Category (Verb + Noun):
              </div>
              <div class="wordMatrix" v-for="(word, index) in props.item.actionMeanPatternLabels.categoryLabelTensor" align="center" v-bind:key="'amc'+word+index">
                <div class="cbContainer">
                  <input type="checkbox" class="thecheckbox" :checked="amcHolder[index]" @change="e => changeCbValue('amc', index, e, props.item.minderId)">
                </div>
              </div>
            </div>
          </div>
          <div v-else>



          </div>
          <br />
          <br />
          <div class="comboBoxWrapper">
            <v-combobox
              :value="actionMeanContextCategoryListSelect"
              @change="e => changeContextCategoryValue('actionMean', e, props.item.minderId)"            
              :items="actionMeanContextCategoryList"
              label="Select a context category, or add a new one"
              v-bind:key="'actionMeanContextCategory'"
            ></v-combobox>
            <br />
            <v-combobox
              :value="actionMeanSpecificityLevelListSelect"
              :items="actionMeanSpecificityLevelList"
              @change="e => changeSpecificityLevelValue('actionMean', e, props.item.minderId)"
              label="General or Specific"
              v-bind:key="'actionMeanSpecificityLevel'">
            </v-combobox>
            <br />
            <v-combobox
              :value="actionMeanTagListSelect"               
              :items="actionMeanTagList"
              @change="e => changeTagListValue('actionMean', e, props.item.minderId)"
              label="Select a tag, or add a new one"
              v-bind:key="'actionMeanTagList'"
              multiple
            ></v-combobox>
            <br />
          </div>
        </div>
      </div>
    </template>

    
      
    
    <template slot="expand" slot-scope="props">
      

      <v-btn 
        v-if="
          (props.item.intentMeanStatementStatus === 'just created') ||
              (props.item.endStatementStatus === 'just created') ||
              (props.item.valueStatementStatus === 'just created') ||
              (props.item.actionMeanStatementStatus === 'just created')
        " 
        @click="analyzeStatements(props.item)" 
        :color="analyzeStatementButtonColor" 
        type="submit"
      >
        
        Analyze Statements
      </v-btn>

      <div class="itemButtons">
          <v-btn 
            color="primary"
            @click="submitTrainingExample(props.item)"
            v-if="
              (props.item.intentMeanStatementStatus === 'ready to process') ||
              (props.item.endStatementStatus === 'ready to process') ||
              (props.item.valueStatementStatus === 'ready to process') ||
              (props.item.actionMeanStatementStatus === 'ready to process')
            "
          >
            Submit Statements
          </v-btn>
        </div>

    </template>
  </v-data-table>
  <v-bottom-sheet v-model="bottomSheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="bottomSheet = !bottomSheet"
        >close</v-btn>
        <div class="py-3">This is a bottom sheet using the controlled by v-model instead of activator</div>
      </v-sheet>
    </v-bottom-sheet>
</v-app>
</template>
<script>
import '@/plugins/vuetify'
import minderModule from '@/store/minder'
import statementModule from '@/store/statement'
import userModule from '@/store/user'
import searchModule from '@/store/search'
import settingsModule from '@/store/settings'
import moderationModule from '@/store/moderation'
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'
import VueContentPlaceholders from 'vue-content-placeholders'
import moment from 'moment-timezone'
Vue.use(VueContentPlaceholders)


export default {
  name: 'minderList',
  components: {},
  data () {
    return {
      expanded: [],
      bottomSheet: false,

      intentMeanStatementBeingAnalyzed: false,
      endStatementBeingAnalyzed: false,
      valueStatementBeingAnalyzed: false,
      actionMeanStatementBeingAnalyzed: false,

      analyzeStatementButtonColor: "primary",

      intentMeanStatementTextValue: null,
      endStatementTextValue: null,
      valueStatementTextValue: null,
      actionMeanStatementTextValue: null,

      showModerationPanel: true,

      

      

      //intentMeanContextCategoryListSelect: null,
      //endContextCategoryListSelect: null,
      //valueContextCategoryListSelect: null,
      //actionMeanContextCategoryListSelect: null,

      
      //intentMeanTagSelect: [],
      //endTagSelect: [],
      //valueTagSelect: [],
      //actionMeanTagSelect: [],

      intentMeanSpecificityLevelList: ['General', 'Specific'],
      actionMeanSpecificityLevelList: ['General', 'Specific'],
      //intentMeanSpecificityLevelSelect: null,
      //actionMeanSpecificityLevelSelect: null,


      //imvHolder: [1,1],

      rules: [
        
      ],
      headers: [
        { sortable: false },
        { text: 'Mean', value: 'props.item.intentMeanStatementText', align: 'left', sortable: false },
        { sortable: false },
        { text: 'End', value: 'props.item.endStatementText', align: 'left' },
        { sortable: false },
        { text: 'Value', value: 'props.item.valueStatementText', align: 'left' },
        { text: 'Actions', sortable: false }
      ],
      show: false,
      
    }
  },
  computed: {
    ...mapGetters('moderation', [
      'intentMeanContextCategoryList',
      'intentMeanContextCategoryListSelect',

      'intentMeanParentCategoryList',
      'intentMeanParentCategoryListSelect',

      'endContextCategoryList',
      'endContextCategoryListSelect',

      'valueContextCategoryList',
      'valueContextCategoryListSelect',

      'actionMeanContextCategoryList',
      'actionMeanContextCategoryListSelect',

      'intentMeanTagList',
      'intentMeanTagListSelect',

      'endTagList',
      'endTagListSelect',

      'valueTagList',
      'valueTagListSelect',

      'actionMeanTagList',
      'actionMeanTagListSelect',

      'intentMeanSpecificityLevelListSelect',
      'actionMeanSpecificityLevelListSelect',

      'imvHolder',
      'imvmHolder',
      'imnHolder',
      'imnmHolder',
      'imcHolder',

      'evHolder',
      'evmHolder',
      'enHolder',
      'enmHolder',
      'ecHolder',

      'vvHolder',
      'vvmHolder',
      'vnHolder',
      'vnmHolder',
      'vcHolder',

      'amvHolder',
      'amvmHolder',
      'amnHolder',
      'amnmHolder',
      'amcHolder',

      'trainingExampleCount',

      'addToRoutineCta',
      'keepAJournalCta',
      'focusOnThisCta',

    ]),
    ...mapGetters('minder', {
      minders: 'list',
      
      loading: 'loading',
      showSpinner: 'showSpinner',

    }),
    /*imvHolder: {
      get() {
        
        return this.$store.state.moderation.imvHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_IMVHOLDER', value, {root: true})
      }
    },/*
    imvmHolder: {
      get() {
        return this.$store.state.moderation.imvmHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_IMVMHOLDER', value, {root: true})
      }
    },
    imnHolder: {
      get() {
        return this.$store.state.moderation.imnHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_IMNHOLDER', value, {root: true})
      }
    },
    imnmHolder: {
      get() {
        return this.$store.state.moderation.imnmHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_IMNMHOLDER', value, {root: true})
      }
    },
    imcHolder: {
      get() {
        return this.$store.state.moderation.imcHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_IMCHOLDER', value, {root: true})
      }
    },

    evHolder: {
      get() {
        return this.$store.state.moderation.evHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_EVHOLDER', value, {root: true})
      }
    },
    evmHolder: {
      get() {
        return this.$store.state.moderation.evmHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_EVMHOLDER', value, {root: true})
      }
    },
    enHolder: {
      get() {
        return this.$store.state.moderation.enHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_ENHOLDER', value, {root: true})
      }
    },
    enmHolder: {
      get() {
        return this.$store.state.moderation.enmHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_ENMHOLDER', value, {root: true})
      }
    },
    ecHolder: {
      get() {
        return this.$store.state.moderation.ecHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_ECHOLDER', value, {root: true})
      }
    },


    vvHolder: {
      get() {
        return this.$store.state.moderation.vvHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_VVHOLDER', value, {root: true})
      }
    },
    vvmHolder: {
      get() {
        return this.$store.state.moderation.vvmHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_VVMHOLDER', value, {root: true})
      }
    },
    vnHolder: {
      get() {
        return this.$store.state.moderation.vnHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_VNHOLDER', value, {root: true})
      }
    },
    vnmHolder: {
      get() {
        return this.$store.state.moderation.vnmHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_VNMHOLDER', value, {root: true})
      }
    },
    vcHolder: {
      get() {
        return this.$store.state.moderation.vcHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_VCHOLDER', value, {root: true})
      }
    },

    amvHolder: {
      get() {
        return this.$store.state.moderation.amvHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_AMVHOLDER', value, {root: true})
      }
    },
    amvmHolder: {
      get() {
        return this.$store.state.moderation.amvmHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_AMVMHOLDER', value, {root: true})
      }
    },
    amnHolder: {
      get() {
        return this.$store.state.moderation.amnHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_AMNHOLDER', value, {root: true})
      }
    },
    amnmHolder: {
      get() {
        return this.$store.state.moderation.amnmHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_AMNMHOLDER', value, {root: true})
      }
    },
    amcHolder: {
      get() {
        return this.$store.state.moderation.amcHolder
      },
      set(value) {
        this.$store.commit('moderation/SET_AMCHOLDER', value, {root: true})
      }
    },*/


    
  },
  methods: {
    ...mapActions('moderation', [
      'createStatements',
      'createPatternInFirestore',
      'updateContextCategories',
      'getIntentMeanContextCategories',
      'getIntentMeanParentCategories',
      'getEndContextCategories',
      'getValueContextCategories',
      'getActionMeanContextCategories',
      'getIntentMeanTags',
      'getEndTags',
      'getValueTags',
      'getActionMeanTags',
      'setHolders',
      'getPatternArray',
      'getTrainingExampleCount',
      'resetValuesAndTags',
      'deleteStatements',
    ]),
    ...mapActions('minder', {
      getMinders: 'getMinders',
      removeMinder: 'removeMinder',
      closeMinder: 'closeMinder',
      redoMinder: 'redoMinder',
      updateMinderStatuses: 'updateMinderStatuses'
    }),
    ...mapActions('search', [
        'setShowSearch'
      ]),
    changeTagListValue(type, event, id) {
      this.$store.dispatch("moderation/changeTagListValue", { type, event, id }); 
    },
    changeSpecificityLevelValue(type, event, id) {
      this.$store.dispatch("moderation/changeSpecificityLevelValue", { type, event, id }); 
    },
    changeContextCategoryValue(type, event, id) {
      this.$store.dispatch("moderation/changeContextCategoryValue", { type, event, id }); 
    },
    changeParentCategoryValue(type, event, id) {
      //this.changeParentCategoryValue({ type, event, id })
      this.$store.dispatch("moderation/changeParentCategoryValue", { type, event, id }); 
    },
    changeCbValue(type, index, event, id) {
      this.$store.dispatch("moderation/changeCbValue", { type, index, event, id }); 
    },

 


    async submitTrainingExample(item) {



      //this.bottomSheet = true
      if (item.intentMeanStatementTaxonomyStatus === 'needs moderation') {
        var intentMeanObj = {
          'pos': item.intentMeanStatementPatterns.statementPosPatternSlug,
          'dep': item.intentMeanStatementPatterns.statementDepPatternSlug,
          'depLabel': item.intentMeanStatementPatterns.statementLabelPatternSlug,
          //'c': this.getThePatternArray({type: 'imc', tensor: item.intentMeanPatternLabels.categoryLabelTensor}),
          'c': item.intentMeanPatternLabels.categoryLabelTensor,
          'n': item.intentMeanPatternLabels.nounLabelTensor,
          'nm': item.intentMeanPatternLabels.nounModLabelTensor,
          'v': item.intentMeanPatternLabels.verbLabelTensor,
          'vm': item.intentMeanPatternLabels.verbModLabelTensor,
        }
        await this.createPatternInFirestore(intentMeanObj)

        //console.log('intentMeanObj: ', JSON.stringify(intentMeanObj))
      } 
      
      if (item.endStatementTaxonomyStatus === 'needs moderation') {
        var endObj = {
          'pos': item.endStatementPatterns.statementPosPatternSlug,
          'dep': item.endStatementPatterns.statementDepPatternSlug,
          'depLabel': item.endStatementPatterns.statementLabelPatternSlug,
          'c': item.endPatternLabels.categoryLabelTensor,
          'n': item.endPatternLabels.nounLabelTensor,
          'nm': item.endPatternLabels.nounModLabelTensor,
          'v': item.endPatternLabels.verbLabelTensor,
          'vm': item.endPatternLabels.verbModLabelTensor,
        }
        await this.createPatternInFirestore(endObj)
        //console.log('endObj: ', JSON.stringify(endObj))
      } 

      if (item.valueStatementTaxonomyStatus === 'needs moderation') {
        var valueObj = {
          'pos': item.valueStatementPatterns.statementPosPatternSlug,
          'dep': item.valueStatementPatterns.statementDepPatternSlug,
          'depLabel': item.valueStatementPatterns.statementLabelPatternSlug,
          'c': item.valuePatternLabels.categoryLabelTensor,
          'n': item.valuePatternLabels.nounLabelTensor,
          'nm': item.valuePatternLabels.nounModLabelTensor,
          'v': item.valuePatternLabels.verbLabelTensor,
          'vm': item.valuePatternLabels.verbModLabelTensor,
        }
        await this.createPatternInFirestore(valueObj)
        //console.log('valueObj: ', JSON.stringify(valueObj))
      } 

      if (item.actionMeanStatementTaxonomyStatus === 'needs moderation') {
        var actionMeanObj = {
          'pos': item.actionMeanStatementPatterns.statementPosPatternSlug,
          'dep': item.actionMeanStatementPatterns.statementDepPatternSlug,
          'depLabel': item.actionMeanStatementPatterns.statementLabelPatternSlug,
          'c': item.actionMeanPatternLabels.categoryLabelTensor,
          'n': item.actionMeanPatternLabels.nounLabelTensor,
          'nm': item.actionMeanPatternLabels.nounModLabelTensor,
          'v': item.actionMeanPatternLabels.verbLabelTensor,
          'vm': item.actionMeanPatternLabels.verbModLabelTensor,
        }
        await this.createPatternInFirestore(actionMeanObj)
        //console.log('actionMeanObj: ', JSON.stringify(actionMeanObj))
      } 


      //console.log('data.actionMeanContextCategory: ',item.actionMeanContextCategory)   //use the data from the minder
      //console.log('data.actionMeanTaxonomy.actionMeanTagList', item.actionMeanTaxonomy.actionMeanTagList)

      /*var intentMeanData = {}
      //update minder's context categories - should be done in cloud function
      intentMeanData.minderId = item.minderId
      intentMeanData.statementType = 'intentMean'
      intentMeanData.contextCategoryListSelect = this.intentMeanContextCategoryListSelect
      intentMeanData.specificityLevelSelect = this.intentMeanSpecificityLevelListSelect
      if(data.actionMeanTaxonomy.intentMeanTagList.length !== 0) {
        intentMeanData.tagListSelect = data.actionMeanTaxonomy.intentMeanTagList
      }else{
        intentMeanData.tagListSelect = []
      }
      await this.updateContextCategories(intentMeanData)
      console.log('intentMeanData: ', JSON.stringify(intentMeanData))


      var endData = {}
      endData.minderId = item.minderId
      endData.statementType = 'end'
      endData.contextCategoryListSelect = this.endContextCategoryListSelect
      if(this.endTagListSelect.length !== 0) {
        endData.tagListSelect = this.endTagListSelect
      }else{
        endData.tagListSelect = []
      }
      await this.updateContextCategories(endData)
      console.log('endData: ', JSON.stringify(endData))

      
      var valueData = {}
      valueData.minderId = item.minderId
      valueData.statementType = 'value'
      valueData.contextCategoryListSelect = this.valueContextCategoryListSelect
      if(this.valueTagListSelect.length !== 0) {
        valueData.tagListSelect = this.valueTagListSelect
      }else{
        valueData.tagListSelect = []
      }
      await this.updateContextCategories(valueData)
      console.log('valueData: ', JSON.stringify(valueData))


      var actionMeanData = {}
      //update minder's context categories - should be done in cloud function
      actionMeanData.minderId = item.minderId
      actionMeanData.statementType = 'actionMean'
      actionMeanData.contextCategoryListSelect = this.actionMeanContextCategoryListSelect
      actionMeanData.specificityLevelSelect = this.actionMeanSpecificityLevelListSelect
      if(this.actionMeanTagListSelect.length !== 0) {
        actionMeanData.tagListSelect = this.actionMeanTagListSelect
      }else{
        actionMeanData.tagListSelect = []
      }
      await this.updateContextCategories(actionMeanData)
      console.log('actionMeanData: ', JSON.stringify(actionMeanData))
      */

     
        //mark the status of each statement as 'ready for taxonomy'
        //update minder's taxonomy status to 'ready for taxonomy'

      var statusData = {
        'minderId': item.minderId,
        'imTaxStatus': 'ready for taxonomy',
        'eTaxStatus': 'ready for taxonomy',
        'vTaxStatus': 'ready for taxonomy',
        'amTaxStatus': 'ready for taxonomy',
        'taxStatus': 'ready for taxonomy',
        'nextStep': 'get the taxonomy',
        //'hasBeenModerated': true
      }
      // Update taxonomyStatus
      this.updateMinderStatuses(statusData)     //uncomment this later to have application go to next phase
        
      //this.resetAll()
      //this.resetValuesAndTags()
      
    },
    async reanalyzeStatements (item) {
      await this.deleteStatements(item)
      this.analyzeStatements(item)
    },
    analyzeStatements (item) {
      this.intentMeanStatementBeingAnalyzed = true
      this.endStatementBeingAnalyzed = true
      this.valueStatementBeingAnalyzed = true
      this.actionMeanStatementBeingAnalyzed = true

      this.analyzeStatementButtonColor = "disabled"

      this.intentMeanStatementTextValue = document.getElementById('intentMeanStatementTextField').value
      this.endStatementTextValue = document.getElementById('endStatementTextField').value
      this.valueStatementTextValue = document.getElementById('valueStatementTextField').value
      this.actionMeanStatementTextValue = document.getElementById('actionMeanStatementTextField').value

      //This is a temporary solution
      var stimulusObj1 = {
        m: 'What behavior, or action, do you wish to start doing?',
        e: 'Why? What result are you trying to achieve?',
        v: 'What happens if you DO NOT do this?',
        a: 'What is the very next action step you could take towards this goal?'
      }
      var stimulusObj2 = {
        m: 'What behavior, or action, do you wish to start doing?',
        e: 'Why? What result are you trying to achieve?',
        v: 'What happens if you DO NOT do this?',
        a: 'What is a specific, next action step you could take towards this goal?'
      }

      var date = moment(item.dateCreated.seconds*1000)
        //console.log("date: ",date)
      var checkDate = moment('2019-12-13 14:00:00')
        //console.log("checkDate: ",checkDate)
      var statementStimulusObj = null
      if(date > checkDate) {
        statementStimulusObj = stimulusObj2
      } else {
        statementStimulusObj = stimulusObj1
      }
      var obj = {
        'intentMeanStatementStimulusText': statementStimulusObj.m,
        'intentMeanStatementText': this.intentMeanStatementTextValue,
        'endStatementStimulusText': statementStimulusObj.e,
        'endStatementText': this.endStatementTextValue,
        'valueStatementStimulusText': statementStimulusObj.v,
        'valueStatementText': this.valueStatementTextValue,
        'actionMeanStatementStimulusText': statementStimulusObj.a,
        'actionMeanStatementText': this.actionMeanStatementTextValue,
        'minderId': item.minderId,
        'intentMeanStatementId': item.intentMeanStatementId,
        'endStatementId': item.endStatementId,
        'valueStatementId': item.valueStatementId,
        'actionMeanStatementId': item.actionMeanStatementId,
      }
      //console.log(JSON.stringify(obj))
      try {
        this.createStatements(obj)
      } catch (e) {
        //console.log("Error: ", e)
      }




    },




    remove (item) {
      const index = this.minders.indexOf(item)
      var minderId = item.minderId
      var imsId = item.intentMeanStatementId
      var esId = item.endStatementId
      var vsId = item.valueStatementId
      var amsId = item.actionMeanStatementId
      this.removeMinder({index: index, minderId: minderId, imsId: imsId, esId: esId, vsId: vsId, amsId: amsId})
    },
    closeTheMinder (item) {
      const index = this.minders.indexOf(item)
      var minderId = item.minderId
      this.closeMinder({index: index, minderId: minderId})
    },
    redoTheMinder (item) {
      const index = this.minders.indexOf(item)
      var minderId = item.minderId
      var imsId = item.intentMeanStatementId
      var esId = item.endStatementId
      var vsId = item.valueStatementId
      var amsId = item.actionMeanStatementId
      this.redoMinder({index: index, minderId: minderId, imsId: imsId, esId: esId, vsId: vsId, amsId: amsId})
    },
    generateRandomNumber () {
      return Number(Math.floor(Math.random() * 90000) + 10000)
    },
    /*
    submitTaxonomyRules (item) {
      var intentMeanTaxStatus = item.intentMeanTaxonomy.intentMeanTaxonomyStatus
      this.newIntentMeanPattern.impos = item.intentMeanTaxonomy.intentMeanPosPattern
      this.newIntentMeanPattern.imdep = item.intentMeanTaxonomy.intentMeanDepPattern
      this.newIntentMeanPattern.imdepLabel = item.intentMeanTaxonomy.intentMeanLabelPattern

      var endTaxStatus = item.endTaxonomy.endTaxonomyStatus
      this.newEndPattern.epos = item.endTaxonomy.endPosPattern
      this.newEndPattern.edep = item.endTaxonomy.endDepPattern
      this.newEndPattern.edepLabel = item.endTaxonomy.endLabelPattern

      var valueTaxStatus = item.valueTaxonomy.valueTaxonomyStatus
      this.newValuePattern.vpos = item.valueTaxonomy.valuePosPattern
      this.newValuePattern.vdep = item.valueTaxonomy.valueDepPattern
      this.newValuePattern.vdepLabel = item.valueTaxonomy.valueLabelPattern

      var actionMeanTaxStatus = item.actionMeanTaxonomy.actionMeanTaxonomyStatus
      this.newActionMeanPattern.ampos = item.actionMeanTaxonomy.actionMeanPosPattern
      this.newActionMeanPattern.amdep = item.actionMeanTaxonomy.actionMeanDepPattern
      this.newActionMeanPattern.amdepLabel = item.actionMeanTaxonomy.actionMeanLabelPattern


      if (intentMeanTaxStatus === 'needs moderation') {
        this.createIntentMeanPatternInFirestore(this.newIntentMeanPattern)
        intentMeanTaxStatus = 'ready for taxonomy'
      }
      if (endTaxStatus === 'needs moderation') {
        this.createEndPatternInFirestore(this.newEndPattern)
        endTaxStatus = 'ready for taxonomy'
      }
      if (valueTaxStatus === 'needs moderation') {
        this.createValuePatternInFirestore(this.newValuePattern)
        valueTaxStatus = 'ready for taxonomy'
      }
      if (actionMeanTaxStatus === 'needs moderation') {
        this.createActionMeanPatternInFirestore(this.newActionMeanPattern)
        actionMeanTaxStatus = 'ready for taxonomy'
      }

      // get minder minderId
      var minderId = item.minderId
      var taxStatus = 'ready for taxonomy'
      // Update minder meanTaxonomy.meanTaxonomyStatus to 'ready for taxonomy'
      var data = {
        minderId: minderId,
        imTaxStatus: intentMeanTaxStatus,
        eTaxStatus: endTaxStatus,
        vTaxStatus: valueTaxStatus,
        amTaxStatus: actionMeanTaxStatus,
        taxStatus: taxStatus,
        nextStep: 'apply groupings and tags'
      }
      // Update taxonomyStatus
      this.updateMinderStatuses(data)
      this.resetIntentMeanPattern()
      this.resetEndPattern()
      this.resetValuePattern()
      this.resetActionMeanPattern()
    },*/
    /*
    submitTags (item) {
      if(this.intentMeanTagListSelect[0] !== null) {
          var intentMeanTagData = {
            minderId: item.minderId,
            intentMeanTagList: this.intentMeanTagListSelect,
            intentMeanCategory: item.intentMeanTaxonomy.category
          }
          this.assignIntentMeanTags(intentMeanTagData)
      }
      if(this.endTagListSelect[0] !== null) {
          var endTagData = {
            minderId: item.minderId,
            endTagList: this.endTagListSelect,
            endCategory: item.endTaxonomy.category
          }
          this.assignEndTags(endTagData)
      }
      if(this.valueTagListSelect[0] !== null) {
          var valueTagData = {
            minderId: item.minderId,
            valueTagList: this.valueTagListSelect,
            valueCategory: item.valueTaxonomy.category
          }
          this.assignValueTags(valueTagData)
      }
      if(this.actionMeanTagListSelect[0] !== null) {
          var actionMeanTagData = {
            minderId: item.minderId,
            actionMeanTagList: this.actionMeanTagListSelect,
            actionMeanCategory: item.actionMeanTaxonomy.category
          }
          this.assignActionMeanTags(actionMeanTagData)
      }

      this.intentMeanTagListSelect = []
      this.endTagListSelect = []
      this.valueTagListSelect = []
      this.actionMeanTagListSelect = []

    },
    submitGroupingsAndTags (item) {
      console.log("item.meanTaxonomy.meanTaxonomyStatus", item.meanTaxonomy.meanTaxonomyStatus)
      var mTaxStatus = item.meanTaxonomy.meanTaxonomyStatus
      var eTaxStatus = item.endTaxonomy.endTaxonomyStatus
      var vTaxStatus = item.valueTaxonomy.valueTaxonomyStatus
      var nextStep = item.nextStep

      var meanGroupPromise
      var endGroupPromise
      var valueGroupPromise

      
      if((this.intentMeanContextCategoryList == null) || (item.intentMeanTaxonomy.intentMeanSpecificityLevel == null))  {
          var intentMeanGData = {
            minderId: item.minderId,
            intentMeanContextCategoryList: this.intentMeanContextCategoryListSelect,
            intentMeanCategory: item.intentMeanTaxonomy.category,
            intentMeanSpecificityLevel: this.intentMeanSpecificityLevelSelect
          }
          intentMeanContextCategoriesPromise = this.assignIntentMeanContextCategories(intentMeanData)
          
      } else { intentMeanContextCategoriesPromise = null }


      if(this.intentMeanTagListSelect[0] !== null) {
          var intentMeanTagData = {
            minderId: item.minderId,
            intentMeanTagList: this.intentMeanTagListSelect,
            intentMeanCategory: item.intentMeanTaxonomy.category
          }
          this.assignIntentMeanTags(intentMeanTagData)
      }


      if((this.endContextCategoryListSelect !== null) && (eTaxStatus !== "grouping complete")) {
          var endData = {
            minderId: item.minderId,
            endContextCategoryList: this.endContextCategoryListSelect,
            endCategory: item.endTaxonomy.category
          }
          endContextCategoriesPromise = this.assignEndContextCategories(endData)
          
      }else { endContextCategoriesPromise = null}


      if(this.endTagListSelect[0] !== null) {
          var endTagData = {
            minderId: item.minderId,
            endTagList: this.endTagListSelect,
            endCategory: item.endTaxonomy.category
          }
          this.assignEndTags(endTagData)
      }


      if((this.valueContextCategoryListSelect !== null) && (vTaxStatus !== "grouping complete")) {
          var valueData = {
            minderId: item.minderId,
            valueContextCategoryList: this.valueContextCategoryListSelect,
            valueCategory: item.valueTaxonomy.category
          }
          valueContextCategoriesPromise = this.assignValueContextCategories(valueData)
      } else { valueContextCategoriesPromise = null}
      if(this.valueTagListSelect[0] !== null) {
          var valueTagData = {
            minderId: item.minderId,
            valueTagList: this.valueTagListSelect,
            valueCategory: item.valueTaxonomy.category
          }
          this.assignValueTags(valueTagData)
      }


      if((this.actionMeanContextCategoryList == null) || (item.actionMeanTaxonomy.actionMeanSpecificityLevel == null))  {
          var actionMeanData = {
            minderId: item.minderId,
            actionMeanContextCategoryList: this.actionMeanContextCategoryListSelect,
            actionMeanCategory: item.actionMeanTaxonomy.category,
            actionMeanSpecificityLevel: this.actionMeanSpecificityLevelSelect
          }
          actionMeanContextCategoriesPromise = this.assignActionMeanContextCategories(actionMeanData)
          
      } else { actionMeanContextCategoriesPromise = null }


      if(this.actionMeanTagListSelect[0] !== null) {
          var actionMeanTagData = {
            minderId: item.minderId,
            actionMeanTagList: this.actionMeanTagListSelect,
            actionMeanCategory: item.actionMeanTaxonomy.category
          }
          this.assignActionMeanTags(actionMeanTagData)
      }


      Promise.all([
        intentMeanContextCategoriesPromise,
        endContextCategoriesPromise,
        valueContextCategoriesPromise,
        actionMeanContextCategoriesPromise,

      ]).then(result => {   //Learned something valuable here.  Need pipe operator to make this.functions bind correctly
        // get minder minderId
        var minderId = item.minderId
        var taxStatus

        if(result[0] !== null){
          imTaxStatus = result[0]
        }
        if(result[1] !== null){
          eTaxStatus = result[1]
        }
        if(result[2] !== null){
          vTaxStatus = result[2]
        }
        if(result[3] !== null){
          amTaxStatus = result[3]
        }

        if(
          (imTaxStatus === "grouping complete") && 
          (eTaxStatus === "grouping complete") && 
          (vTaxStatus === "grouping complete") &&
          (amTaxStatus === "grouping complete")
          ) {
            taxStatus = "taxonomy complete"
            nextStep = "process heedi example"
          } else {
            taxStatus = "needs grouping"
          }

        console.log("imTaxStatus: "+ imTaxStatus)
        console.log("eTaxStatus: "+ eTaxStatus)
        var data = {
          minderId: minderId,
          imTaxStatus: imTaxStatus,
          eTaxStatus: eTaxStatus,
          vTaxStatus: vTaxStatus,
          amTaxStatus: amTaxStatus,
          taxStatus: taxStatus,
          nextStep: nextStep
        }
        // Update taxonomyStatus
        this.updateMinderStatuses(data)

     })

    },*/
    

  },
  beforeCreate () {
    if(!this.$store.state.minder) this.$store.registerModule('minder', minderModule)
    if(!this.$store.state.statement) this.$store.registerModule('statement', statementModule)
    if(!this.$store.state.user) this.$store.registerModule('user', userModule)
    if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
    if(!this.$store.state.moderation) this.$store.registerModule('moderation', moderationModule)
    if(!this.$store.state.search) this.$store.registerModule('search', searchModule)
  },
  created () {

    this.getMinders()
    this.getTrainingExampleCount()

    this.getIntentMeanContextCategories()
    this.getIntentMeanParentCategories()
    this.getEndContextCategories()
    this.getValueContextCategories()
    this.getActionMeanContextCategories()
    this.getIntentMeanTags()
    this.getEndTags()
    this.getValueTags()
    this.getActionMeanTags()
  },
  mounted () {
    this.setShowSearch(false)
  },
  updated () {
    
    this.getTrainingExampleCount()
  },
  watch: {
    minders: function () {
      if (this.minders.length > 0) {
        var minder = this.minders[0]
        this.$set(this.$refs.dTable.expanded, minder.minderId, true)
      }
    }
  }
}
</script>

<style scoped>

  .moderationDashboardWrapper {
    text-align: center;
  }

  .dashCard {
    display: inline-block;
    box-sizing: border-box;
    width: 15%;
    padding: 3px;
    text-align: center;
    background-color: lightgray;
    margin: 15px;

  }
  .dashValue {
    text-align: left;
    font-size: 3em;
    margin: 5px;
    margin-left: 15px;
    font-weight: 600;
  }
  .dashValueLabel {
    margin: 5px;
    text-align: right;
    font-weight: 400;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 3px;
    max-width: 550px;
    margin: 0 auto 3px;
  }

  .panel {
    background-color: rgb(214, 214, 214);
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
  }

  .chain_box {
    margin-top: 30px;
    display: block;
    width: 100%;
    min-height: 30px;
    background-color: transparent;
  }

  #spinner {
    position: relative;
    top: 2px;
    right: 0px;
  }

  .left_rail {
    position: relative;
    top: 30px;
    display: inline-block;
    box-sizing: border-box;
    font-size: 1em;
    color: rgb(0, 0, 146);
    width: 5%;
    text-align: center;
    background-color: transparent;
    vertical-align: top;
  }

  .right_rail {
    position: relative;
    vertical-align: top;
    top: 5px;
    display: inline-block;
    box-sizing: border-box;
    width: 7%;
    text-align: center;
    background-color: transparent;
  }

  .mev_item_wrapper {
    display: inline-block;
    background-color: transparent;
    width: 100%;
    margin-top: 7px;
    margin-bottom: 7px;
    
  }

  .chainWrapper {
    display: inline-block;
    position: relative;
    width: 88%;
    padding: 3px;
  }
  
  #redoMinderIcon {
    font-size: 1em;
    display: block;
    margin-bottom: 5px;
    margin-top: 3px;
  }
  #deleteMinderIcon {
    font-size: 1.1em;
    display: block;
  }
  #closeMinderIcon {
    font-size: 1.2em;
    display: block;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .statementBullet {
    position: relative;
    right: 10px;
  }
  .statementTitle {
    font-size: .85em;
  }
  .blockTitle {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: 1.6em;
    font-weight: 400;
    background-color: rgb(51, 51, 51);
    color: yellow;
    padding: 2px;
    padding-left: 6px;
  }

  .statementBlock {
    border: rgb(219, 219, 219) solid 1px;
    margin: 0 auto;
    margin-bottom: 100px;
    width: 90%;
    padding: 15px;
  }

  .expandedStatementText {
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }

  .matrixWrapper {
    display: block;
    text-align: left;
  }
  .wordMatrix {
    display: inline-block;
    color: black;
    width: 80px;
    margin-right: 2px;
    font-size: .9em;
    overflow: hidden;
    border: 1px solid #b3b3b3;
    background-color: #eeeeee;
  }

  .words {
    font-size: 1.1em;
    font-weight: 800;
    background-color: darkgray
  }

  .leftRailMatrix {
    display: inline-block;
    font-size: .70em;
    box-sizing: border-box;
    margin-right: 2px;
    width: 10%;
    min-width: 26px;
    line-height: 1.1em;
    vertical-align: top;
    padding: 5px;
    
  }

  .comboBoxWrapper {
    position: relative;
    width: 60%;
    left: 10%;
  }
  
  input.thecheckbox {
    margin: 0;
    margin-top: 3px;
  }

  .itemButtons {
    text-align: center;
    margin: 15px;
  }

  .itemFooter {
    text-align: center;
  }


  .shimmer1 {
    width: 250px;
    height: 20px;
  }
  .shimmer2 {
    width: 210px;
    height: 20px;
  }
  .shimmer3 {
    width: 230px;
    height: 20px;
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  
  table.skinny_cells td:not(:first-child) {
    padding: 0 2px;
  }
  table.skinny_cells td:first-child {
    padding: 0 2px;
  }

  

  @media screen and (min-width: 400px) {

    

  }

  @media screen and (min-width: 640px) {


    

  }

  @media screen and (min-width: 700px) {

    
    

  }


  @media screen and (min-width: 900px) {
    

    
  }


  @media screen and (min-width: 1000px) {
    
  }

  @media screen and (min-width: 1300px) {
    
  }

  @media screen and (min-width: 1500px) {
 
  }
  @media screen and (min-width: 1550px) {


  }


</style>
